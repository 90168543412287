import * as React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Container, IconButton, Toolbar } from "@mui/material";
import logo from "./iress_logo.png";
import UserAccountMenu from './nav/user-menu-mui';
import BtnLogin from '../buttons/btn-login';
import MainMenu from './nav/main-menu-mui';
import { rootState } from '../../rootState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

interface NavBarProps {
    state: rootState;
}

interface NavBarDispatch{

}

type Props = NavBarProps & NavBarDispatch;

const MuiNavBar = (props:Props) => {
    const { isAuthenticated } = useAuth0();
    const [open, setOpen] = React.useState(false);

    const toggleMainMenu = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };


    return (
        <AppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters >
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={toggleMainMenu(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <img
                            className="nav-bar-logo"
                            src={logo}
                            alt="iress logo"
                        />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginRight: 3 }}>
                        <img
                            className="nav-bar-logo"
                            src={logo}
                            alt="iress logo"
                        />
                    </Box>
                    {isAuthenticated ? (
                        <UserAccountMenu user={props.state.user} />
                    ) :
                        (
                            <BtnLogin />
                        )}
                </Toolbar>
                <MainMenu toggleFunction={setOpen} openState={open} />
            </Container>
        </AppBar>

    );
}


const mapStateToProps = (state: rootState): NavBarProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): NavBarDispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps) (MuiNavBar);