import { useAuth0 } from "@auth0/auth0-react";
import { Button, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

export const NavButton: React.FC<btnProps> = (props) => {

    // use Auth0 library to login with redirect
    const { loginWithRedirect, logout } = useAuth0();
    const navigate = useNavigate();

    const handleClick = async () => {

        switch (props.auth0_function) {
            case "signup": {
                await loginWithRedirect({
                    // redirect url after login process
                    appState: {
                        returnTo: props.route,
                    },
                    authorizationParams: {
                        prompt: "login",
                        screen_hint: "signup",
                    },
                });
                break;
            }
            case "logout": {
                logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                });
                break;
            }
            case "login": {
                await loginWithRedirect({
                    appState: {
                        returnTo: "/callback",
                    },
                    authorizationParams: {
                        prompt: "login",
                    },
                });
                break;
            }

            default: {
                navigate(props.route ? props.route : "/check")
            }
        }

    };
    return props.is_menu ? (
        <MenuItem key="logout" onClick={handleClick}>
            <Typography textAlign="center">{props.label}</Typography>
        </MenuItem>
    ) : (
        <Button key="dashboard"
            sx={{ my: 2, color: 'white', display: 'block' }}
            onClick={handleClick} >
            {props.label}
        </Button>
    )
};

interface btnProps {
    is_menu: boolean;
    label: string;
    auth0_function?: any;
    route: string;
}