import * as React from 'react';
import { Box } from "@mui/material";
import { NavButton } from './btn-nav';

const BtnLogin = () => {
    return (
        <Box sx={{ flexGrow: 0 }}>
            <NavButton
                is_menu={false}
                label="Login"
                auth0_function="login"
                route="/dashboard"
            />
        </Box >
    );
}

export default BtnLogin;