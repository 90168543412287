import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ChevronLeft, Dashboard, HelpOutline, LockPerson } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { rootState } from '../../../rootState';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { iDashboardItem } from '../../../state/dashboardState';
import { useAuth0 } from '@auth0/auth0-react';
import { setDashboardActiveAction } from '../../../pages/dashboard/reducer';


interface MenuBarProps {
    state: rootState;
}

interface MenuBarDispatch {
    setDashboardActive: (payload) => void;
}

interface MuiProps {
    openState: boolean;
    toggleFunction: any;
}

type Props = MenuBarProps & MenuBarDispatch & MuiProps;


export const MainMenu: React.FC<Props> = (props) => {
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const toggleMenu = () => {
        props.toggleFunction(false);
    }

    // const getDashboardList = () => {
    //     var items: iDashboardItem[];
    //     items = [];
    //     if (props.state.dashboard) {
    //         return props.state.dashboard.DashboardSummaryList.map((dashboard: iDashboardItem) => {
    //             return dashboard;

    //         });
    //     }
    //     return items;
    // }

    const navDashboardList = () => {
        navigate("/dashboard");
    }

    const navigateLogin = async () => {
        await loginWithRedirect({
            // redirect url after login process
            appState: {
                returnTo: "/dashboard",
            },
            authorizationParams: {
                prompt: "login"
            },
        });
    }

    const dashboardItemMenuClick = (dashboard: iDashboardItem) => {
        props.setDashboardActive(dashboard);
        navigate("/dashboard/detail");
    }

    const renderDashboardItems = () => {
        if (props.state.dashboard) {
            if (props.state.dashboard.DashboardSummaryList) {
                if (props.state.dashboard.DashboardSummaryList.length > 0) {
                    return props.state.dashboard.DashboardSummaryList.map((dashboard: iDashboardItem, index) => (
                        <ListItem key={dashboard.Name} disablePadding>
                            <ListItemButton onClick={(e) => dashboardItemMenuClick(dashboard)}>
                                <ListItemIcon>
                                    <Dashboard />
                                </ListItemIcon>
                                <ListItemText primary={dashboard.Name} />
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            }
        }
        return <></>
    }

    const renderDashboardList = () => {
        if (props.state.dashboard) {
            if (props.state.dashboard.DashboardSummaryList) {
                if (props.state.dashboard.DashboardSummaryList.length > 0) {
                    return (
                        <List>
                            <ListItem key="DashboardList" disablePadding >
                                <ListItemButton onClick={(e) => navDashboardList()}>
                                    <ListItemText primary="Dashboards" />
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                            {renderDashboardItems()}
                        </List>
                    )
                }
            }
        }

        return (
            <List>
                <ListItem key="No Dashboards" disablePadding>
                    <ListItemButton onClick={navigateLogin}>
                        <ListItemIcon>
                            <LockPerson />
                        </ListItemIcon>
                        <ListItemText primary="Login" />
                    </ListItemButton>
                </ListItem>
            </List>
        )
    }

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleMenu}>
            <List sx={{ bgcolor: "primary.main", color: "white" }}>
                <ListItemButton>
                    <ListItemText
                        sx={{ my: 0 }}
                        primary="FUND-Flow"
                        primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 'medium',
                            letterSpacing: 0,
                        }}
                    />
                    <ChevronLeft />
                </ListItemButton>
            </List>
            <Divider />
            {renderDashboardList()}
            <Divider />
            <List>
                {['Help'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <HelpOutline />
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Drawer
            open={props.openState}
            onClose={toggleMenu}
            anchor='left'
        >
            {DrawerList}
        </Drawer>
    );
}


const mapStateToProps = (state: rootState): MenuBarProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): MenuBarDispatch => ({
    setDashboardActive: (payload) => dispatch(setDashboardActiveAction(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);