import { Button, Card, CardMedia, CardContent, CardActions, Typography } from '@mui/material';
import { iDashboardItem } from '../../state/dashboardState';
import chart_01 from "../../assets/images/chart_01.png";

const MenuCard = (props:menuCardProps) => {
    
    return (
        <Card variant="outlined" >
            <CardMedia
                image={props.image? props.image:chart_01}
                title={props.title}
                component="img"
                height={140}
            />
            <CardContent >
                <Typography gutterBottom variant='subtitle1' >
                    {props.title}
                </Typography>
                <Typography variant="body2">
                    {props.description? props.description:""}
                </Typography>
                <CardActions sx={{justifyContent:"flex-end"}} >
                    <Button 
                    color="primary" 
                    variant='outlined'
                    onClick={()=>{props.callBackFunction(props.dashboard)}}
                     >View</Button>
                </CardActions>
            </CardContent>
        </Card>
    )
};

interface menuCardProps {
    dashboardId: string;
    dashboard:iDashboardItem;
    title: string;
    description?: string;
    image?: string;
    callBackFunction?: any;
}

export default MenuCard;