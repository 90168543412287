import React, { useEffect, useState } from 'react';
import { rootState } from '../../rootState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { setDashboardActiveAction, setDashboardListAction, setDashboardListCalledAction } from './reducer';
import { Container, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../../components/page-layout';
import { getDashboardList } from '../../services/dashboard-request-service';
import DashboardList from '../../components/dashboard/dashboard-list';
import { iDashboardItem } from '../../state/dashboardState';
import { useNavigate } from 'react-router-dom';
import { GenericLoader } from '../../components/loader';


interface DashboardListProps {
    state: rootState;
}

interface DashboardListDispatch {
    setDashboardList: (payload) => void;
    setDashboardActive: (payload) => void;
    setDashboardListCalled: () => void;
}

type Props = DashboardListProps & DashboardListDispatch;

// main component render
export const Dashboard: React.FC<Props> = (props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDashboardListLoaded, setIsDashboardLoaded] = useState<boolean>(false);
    const navigate = useNavigate();

    // let react know somthing do after render. Here load data after render
    useEffect(() => {
        if (isDashboardListLoaded === false) {
            setIsDashboardLoaded(true);
            setIsLoading(true);
            props.setDashboardListCalled();
            const getDashboardApi = async () => {
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await getDashboardList(accessToken);
                if (data) {
                    props.setDashboardList(data.DashboardSummaryList);
                }
                if (error) {
                    alert("Error:" + error);
                }
                setIsLoading(false);
            }
            getDashboardApi();
        }
    }, [props, isDashboardListLoaded, getAccessTokenSilently, setIsDashboardLoaded]);


    const handleButtonClick = async (dashboard: iDashboardItem) => {
        // set active dashboard navigate to detail page
        props.setDashboardActive(dashboard);
        navigate("/dashboard/detail")
    }

    return (
        <PageLayout>
            {isLoading ? <GenericLoader />
                :
                <Container maxWidth="lg" sx={{ paddingTop: 3 }}>
                    <Grid container spacing={4}  >
                        <DashboardList dashboards={props.state.dashboard.DashboardSummaryList} callBack={handleButtonClick} />
                    </Grid>
                </Container>
            }
        </PageLayout >
    )
}

const mapStateToProps = (state: rootState): DashboardListProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): DashboardListDispatch => ({
    setDashboardList: (payload) => dispatch(setDashboardListAction(payload)),
    setDashboardActive: (payload) => dispatch(setDashboardActiveAction(payload)),
    setDashboardListCalled: () => dispatch(setDashboardListCalledAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);