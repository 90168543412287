import React from "react";
import { Container, Grid, Skeleton } from "@mui/material";

export const GenericLoader: React.FC = () => (
    <Container maxWidth="lg" sx={{ paddingTop: 3 }} >
        <Grid container spacing={3} alignItems="center" justifyContent="center" >
            <Grid item xs={12} md={4} >
                <Skeleton variant="rectangular" height={150} />
                <Skeleton />
                <Skeleton width="60%" />
            </Grid>
            <Grid item xs={12} md={4} >
                <Skeleton variant="rectangular" height={150} />
                <Skeleton />
                <Skeleton width="60%" />
            </Grid>
            <Grid item xs={12} md={4} >
                <Skeleton variant="rectangular" height={150} />
                <Skeleton />
                <Skeleton width="60%" />
            </Grid>
            <Grid item xs={12} md={4} >
                <Skeleton variant="rectangular" height={150} />
                <Skeleton />
                <Skeleton width="60%" />
            </Grid>
            <Grid item xs={12} md={4} >
                <Skeleton variant="rectangular" height={150} />
                <Skeleton />
                <Skeleton width="60%" />
            </Grid>
            <Grid item xs={12} md={4} >
                <Skeleton variant="rectangular" height={150} />
                <Skeleton />
                <Skeleton width="60%" />
            </Grid>
        </Grid>
    </Container>
);
