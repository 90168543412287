
import { dashboardState } from "../../state/dashboardState";


export const DashboardActionTypes = {
    DASHBOARD_LIST_GET: 'DASHBOARD_LIST_GET',
    DASHBOARD_LIST_SET: 'DASHBOARD_LIST_SET',
    DASHBOARD_LINK_GET: 'DASHBOARD_LINK_GET',
    DASHBOARD_ACTIVE_SET: 'DASHBOARD_ACTIVE_SET',
    DASHBOARD_ACTIVE_CALLED: 'DASHBOARD_ACTIVE_CALLED',
    DASHBOARD_LIST_CALLED: 'DASHBOARD_LIST_CALLED',
};

const InitialState: dashboardState = {
    DashboardSummaryList: [],
    DashboardListLoaded: false,
    DashboardLoaded: false,
}

const getDashboardListAction = (payload) => {
    return {
        type: DashboardActionTypes.DASHBOARD_LIST_GET,
        payload
    }
}

const setDashboardListAction = (payload) => {
    return {
        type: DashboardActionTypes.DASHBOARD_LIST_SET,
        payload
    }
}

const setDashboardActiveAction = (payload) => {
    return {
        type: DashboardActionTypes.DASHBOARD_ACTIVE_SET,
        payload
    }
}

const setDashboardActiveCalledAction = () => {
    return {
        type: DashboardActionTypes.DASHBOARD_ACTIVE_CALLED,
    }
}

const setDashboardListCalledAction = () => {
    return {
        type: DashboardActionTypes.DASHBOARD_LIST_CALLED,
    }
}



const dashboardReducer = (state: dashboardState = InitialState, action) => {
    switch (action.type) {
        case DashboardActionTypes.DASHBOARD_LIST_GET:

            return {
                ...state,
                DashboardListLoaded: true,
            }
        case DashboardActionTypes.DASHBOARD_LIST_SET:
            return {
                ...state,
                DashboardlistLoaded: true,
                DashboardSummaryList: action.payload

            }
        case DashboardActionTypes.DASHBOARD_ACTIVE_SET:
            return {
                ...state,
                ActiveDashboard: action.payload,
                DashboardLoaded: false,
            }
        case DashboardActionTypes.DASHBOARD_ACTIVE_CALLED:
            return {
                ...state,
                DashboardLoaded: true,
            }
        case DashboardActionTypes.DASHBOARD_LIST_CALLED:
            return {
                ...state,
                DashboardListLoaded: true,
            }
        default:
            return state;
    }
}

export { dashboardReducer, 
    getDashboardListAction, 
    setDashboardListAction, 
    setDashboardActiveAction, 
    setDashboardActiveCalledAction,
    setDashboardListCalledAction };