import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Auth0ProviderWithHistory } from "./services/auth0-provider-with-history";
import "./styles/styles.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { configureStore } from "./configureStore";


const theme = createTheme({
    palette: {
        primary: {
            main: "#361a3f",
        },
        secondary: {
            main: "#f50057",
        },
    },

})

const rootStore = configureStore()

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <ThemeProvider theme={theme}>
                    <Provider store={rootStore} >
                        <App />
                    </Provider>
                </ThemeProvider>
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
