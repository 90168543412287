import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { PageLayout } from "../../components/page-layout";
import { postRegUser } from "../../services/dashboard-request-service";
import { useNavigate } from "react-router-dom";
import { rootState } from "../../rootState";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { setUserAction } from "./reducer";
import { GenericLoader } from "../../components/loader";

interface CallBackProps {
    state: rootState;
}

interface CallBackDispatch {
    setAuth0User: (payload) => void;
}

type Props = CallBackProps & CallBackDispatch;

// main component render
export const CallbackPage: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { user, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
            
            const regUser = async () => {
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await postRegUser(accessToken);

                props.setAuth0User(user);

                if (data) {
                    navigate("/dashboard");
                }

                if (error) {
                    alert("Error:" + error);
                }
            }

            regUser();
        }
    }, [user, props, isLoading, navigate, getAccessTokenSilently]);

    return (
        <PageLayout>
            <GenericLoader />
        </PageLayout>
    );
};

const mapStateToProps = (state: rootState): CallBackProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): CallBackDispatch => ({
    setAuth0User: (payload) => dispatch(setUserAction(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);