import React from "react";
import { PageLayout } from "../components/page-layout";
import { Container, Grid } from "@mui/material";

export const HomePage: React.FC = () => (
    <PageLayout>
        <Container maxWidth="lg" sx={{paddingTop:3}} >
            <Grid container spacing={2} alignItems="center" justifyContent="center" >
                <Grid item xs={3}>

                </Grid>
            </Grid>
        </Container>
    </PageLayout>
);
