import { userState } from "../../state/userState";

const InitState: userState = {
    name: "",
    auth0User: {
        custom_claims: {
            quicksight: {
                audience: "blablabla",
                groups: ""
            }
        },
        email: ""
    }
}

export const CallBackActionType = {
    SET_USER: "SET_USER",
}

const setUserAction = (payload) => {
    return {
        type: CallBackActionType.SET_USER,
        payload
    }
}

const userReducer = (state: userState = InitState, action: any) => {

    switch (action.type) {
        case CallBackActionType.SET_USER:
            return {
                ...action.payload
            };
        default:
            return state;
    }
};


export { userReducer , setUserAction};