import React from 'react';
import { rootState } from '../../rootState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Avatar, Container, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Stack, Switch, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../../components/page-layout';
import { Settings, ColorLens, Bluetooth } from '@mui/icons-material';


interface SettingProps {
    state: rootState;
}

interface SettingDispatch {

}

type Props = SettingProps & SettingDispatch;

// main component render
export const SettingPage: React.FC<Props> = (props) => {
    const { isAuthenticated } = useAuth0();
    const [checked, setChecked] = React.useState(['wifi']);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <PageLayout>
            {isAuthenticated &&
                <Container maxWidth="lg" sx={{ paddingTop: 3 }} >
                    <Stack direction="row" spacing={2} >
                        <Avatar
                            sx={{ width: 64, height: 64, bgcolor: 'primary.main' }}
                            variant="rounded" >
                            <Settings />
                        </Avatar>
                        <Stack direction="column" >
                            <Typography variant="h6" gutterBottom>Preferences</Typography>
                            <Typography variant="body2" gutterBottom>Application preferences, configurations and settings.</Typography>
                        </Stack>
                    </Stack>
                    <Divider variant='fullWidth' sx={{ paddingTop: 1, paddingBottom: 1 }} />
                    
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>User Interface and    Apperence</ListSubheader>}
                    >
                        <ListItem>
                            <ListItemIcon>
                                <ColorLens />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-darkmode" primary="DarkMode" />
                            <Switch
                                edge="end"
                                onChange={handleToggle('darkmode')}
                                checked={checked.indexOf('darkmode') !== -1}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-darkmode',
                                }}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <Bluetooth />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-bluetooth" primary="Bluetooth" />
                            <Switch
                                edge="end"
                                onChange={handleToggle('bluetooth')}
                                checked={checked.indexOf('bluetooth') !== -1}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-bluetooth',
                                }}
                            />
                        </ListItem>
                        <Divider />
                    </List>
                </Container>
            }
        </PageLayout>
    )
}

const mapStateToProps = (state: rootState): SettingProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): SettingDispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);