import React from "react";
import { PageLayout } from "../components/page-layout";
// import {
//     REACT_APP_API_SERVER_URL,
//     REACT_APP_AUTH0_AUDIENCE,
//     REACT_APP_AUTH0_CALLBACK_URL,
//     REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN,
//     REACT_APP_SOURCE_APPLICATION
// } from "../shared/constants";

export const CheckPage: React.FC = () => {
    // const json_config = {
    //     "REACT_APP_AUTH0_DOMAIN": { REACT_APP_AUTH0_DOMAIN },
    //     "REACT_APP_AUTH0_CLIENT_ID": { REACT_APP_AUTH0_CLIENT_ID },
    //     "REACT_APP_AUTH0_CALLBACK_URL": { REACT_APP_AUTH0_CALLBACK_URL },
    //     "REACT_APP_AUTH0_AUDIENCE": { REACT_APP_AUTH0_AUDIENCE },
    //     "REACT_APP_API_SERVER_URL": { REACT_APP_API_SERVER_URL },
    //     "REACT_APP_SOURCE_APPLICATION": { REACT_APP_SOURCE_APPLICATION }
    // }

    return (
        <PageLayout>
            <div className="content-layout">
                <p><b>Check</b></p>
                <div className="content-body">
                  
                </div>
            </div>
        </PageLayout>
    );
};
