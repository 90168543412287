import React from "react";
import { PageFooter } from "./page-footer";
import MuiNavBar, { } from "./navigation/nav-bar-mui";


interface PageLayoutProps {
    children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
    return (
        <div >
            <MuiNavBar />
                <div style={{paddingTop:65}}>{children}</div>
            <PageFooter />
        </div>
    );
};
