import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { CheckPage } from "./pages/check-page";
import { LoadingPage } from "./pages/loading-page";
import Dashboard from "./pages/dashboard/dashboardList";
import DashboardDetail from "./pages/dashboard/dashboardDetail";
import CallbackPage from "./pages/callback/callback-page";
import ProfilePage from "./pages/profile/profile";
import SettingPage from "./pages/preferences/preferences";


export const App: React.FC = () => {
    // const [state, setStatus] = useState(RootState);
    const { isLoading, isAuthenticated } = useAuth0();


    if (isLoading) {
        return (<LoadingPage />);
    }

    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/check" element={<CheckPage />} />
            <Route path="/profile" element={isAuthenticated ? <ProfilePage /> : <HomePage />} />
            <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <HomePage />} />
            <Route path="/dashboard/detail" element={isAuthenticated ? <DashboardDetail /> : <HomePage />} />
            {/* <ProtectedRoute path="/dashboard" component={DashboardPage} /> */}
            <Route path="/settings" element={<SettingPage />} />
            <Route path="/callback" element={<CallbackPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};
