import React from 'react';
import { rootState } from '../../rootState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Avatar, Container, Divider, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../../components/page-layout';


interface ProfileProps {
    state: rootState;
}

interface ProfileDispatch {

}

type Props = ProfileProps & ProfileDispatch;

// main component render
export const ProfilePage: React.FC<Props> = (props) => {
    const { isAuthenticated } = useAuth0();

    return (
        <PageLayout>
            {isAuthenticated &&
                <Container maxWidth="lg" sx={{ paddingTop: 3 }} >
                    <Stack direction="row" spacing={2} >
                        <Avatar
                            alt={props.state.user.name}
                            src={props.state.user.picture}
                            sx={{ width: 64, height: 64 , bgcolor: 'primary.main'}}
                            variant="rounded" />
                        <Stack direction="column" >
                            <Typography variant="h6" gutterBottom>{props.state.user.name}</Typography>
                            <Typography variant="body2" gutterBottom>{props.state.user.email}</Typography>
                        </Stack>
                    </Stack>
                    <Divider variant='fullWidth' sx={{paddingTop:1, paddingBottom: 1}}/>
                    
                </Container>
            }
        </PageLayout>
    )
}

const mapStateToProps = (state: rootState): ProfileProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): ProfileDispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);