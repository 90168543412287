import { Alert, Grid } from "@mui/material";
import MenuCard from "./card";
import { iDashboardItem } from "../../state/dashboardState";
import chart_01 from "../../assets/images/chart_01.png";
import chart_02 from "../../assets/images/chart_02.jpg";
import chart_03 from "../../assets/images/chart_03.png";
import chart_04 from "../../assets/images/chart_04.jpg";
import chart_05 from "../../assets/images/chart_05.jpg";

type DashboardListProps = {
    dashboards: iDashboardItem[],
    callBack: any
}

const DashboardList = ({ dashboards, callBack }: DashboardListProps) => {

    const getChartImage = (index: number) => {
        switch (index) {
            case 0:
                return chart_01;
            case 1:
                return chart_02;
            case 2:
                return chart_03
            case 3:
                return chart_04
            case 4:
                return chart_05
            default:
                return chart_04
        }
    }
    if (!dashboards) {
        return (
            <div className="page-layout-blank" >
                <Alert severity="info">
                    No dashboards available.
                </Alert>
            </div>
        )
    }

    if (dashboards.length === 0) {
        return (
            <div className="page-layout-blank" >
                <Alert severity="info">
                    No dashboards available.
                </Alert>
            </div>
        )
    }

    return <>
        {dashboards.map((dashboard: iDashboardItem, index: number) => {
            return (
                <Grid item xs={12} md={4} key={index}>
                    <MenuCard
                        dashboardId={dashboard.DashboardId}
                        dashboard={dashboard}
                        image={getChartImage(index)}
                        title={dashboard.Name}
                        description=""
                        callBackFunction={callBack}
                    />
                </Grid>
            )
        })
        }
    </>;
};

export default DashboardList;