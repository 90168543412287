import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

interface IConfig {
    dashboardUri: string,
    container: HTMLElement,
    errorCallback: Function,
    loadCallback: Function,
}

export const loadDashboard = async ({ dashboardUri, container, errorCallback, loadCallback }: IConfig) => {

    const url = dashboardUri;
    const embeddingContext = await createEmbeddingContext();
    const frameOptions = {
        url: url,
        container: container,
        height: '500px',
        resizeHeightOnSizeChangedEvent: true
    };
    const contentOptions = {
        onMessage: async (messageEvent: any, experienceMetadata: any) => {
            switch (messageEvent.eventName) {
                case 'CONTENT_LOADED': {
                    if (typeof loadCallback === "function") { loadCallback(messageEvent); }
                    break;
                }
                case 'ERROR_OCCURRED': {
                    if (typeof errorCallback === "function") { errorCallback(messageEvent.message); } else { console.error("Error occurred :" + messageEvent.message) }
                    break;
                }

            }
        }
    };


    return await embeddingContext.embedDashboard(frameOptions, contentOptions);

};
