import React, { useCallback, useEffect, useRef, useState } from 'react';
import { rootState } from '../../rootState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLayout } from '../../components/page-layout';
import { getDashboardUrl } from '../../services/dashboard-request-service';
import { loadDashboard } from '../service/dashboard';
import { setDashboardActiveCalledAction } from './reducer';
import { GenericLoader } from '../../components/loader';


interface DashboardDetailProps {
    state: rootState;
}

interface DashboardDetailDispatch {
    setDashboardActiveCalled: () => void;
}

type Props = DashboardDetailProps & DashboardDetailDispatch;

// main component render
export const DashboardDetail: React.FC<Props> = (props) => {
    const refDashboardContainer = useRef(null);
    const { getAccessTokenSilently } = useAuth0();
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const loadActiveDashboard = useCallback(async () => {
        const dashboardErrorCallback = (err: any) => {
            alert('Error configuring dashboard' + err);
            setIsLoading(false);
            setIsError(true);
        };


        if (!props.state.dashboard.DashboardLoaded) {
            setIsLoading(true);
            props.setDashboardActiveCalled();

            try {
                if (props.state.dashboard.ActiveDashboard) {
                    const accessToken = await getAccessTokenSilently();
                    const { data, error } = await getDashboardUrl(accessToken, props.state.dashboard.ActiveDashboard.DashboardId);
                    if (data.EmbedUrl) {
                        const url = data.EmbedUrl;

                        setIsLoading(false);
                        const config = {
                            dashboardUri: url,
                            container: refDashboardContainer.current as unknown as HTMLElement,
                            errorCallback: dashboardErrorCallback,
                            loadCallback: () => setIsLoading(false)
                        };

                        loadDashboard(config)
                            .then(() => console.log('Dashboard loading initiated'))
                            .catch((err) => {
                                alert('Error initiating dashboard load ' + err);
                                setIsLoading(false);
                                setIsError(true);
                            });
                    } else {
                        dashboardErrorCallback("Error loading dashboard: " + data.message ? data.message : "");
                        setIsError(true);
                    }

                    if (error) {
                        //  load error message
                        setIsLoading(false);
                        setIsError(true);
                    }
                } else {
                    alert('No active dashboard selected');
                    setIsLoading(false);
                    setIsError(true);
                }
            } catch (error) {
                console.error("Error:", error);
                setIsLoading(false);
                setIsError(true);
            }
        }
    }, [props, refDashboardContainer, getAccessTokenSilently, setIsError, setIsLoading]);


    // let react know somthing do after render. Here load data after render
    useEffect(() => {
        var container = document.getElementById("dashboardContainer");
        if (container) {
            container.innerHTML = "";
        }

        loadActiveDashboard();
    }, [loadActiveDashboard]);


    return (
        <PageLayout>

            {isError && <div className="dashboard-container"></div>}
            {isLoading ? <GenericLoader /> :
                <div id="dashboardContainer" ref={refDashboardContainer} />
            }
        </PageLayout>
    );
}

const mapStateToProps = (state: rootState): DashboardDetailProps => ({
    state
});

const mapDispatchToProps = (dispatch: Dispatch): DashboardDetailDispatch => ({
    setDashboardActiveCalled: () => dispatch(setDashboardActiveCalledAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDetail);